import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import Students from "../Pages/Students/Students";
import brainhapLogo from "../Assets/footerLogo.png";
import logout from "../Assets/logout.png";

import { UserCredsContext } from "../ContextApi/UserCredsContext/UserCredsContext";
import schoolStroke from "../Assets/schoolstroke.png";
import studentStroke from "../Assets/studentStroke.png";
import { Logout } from "@mui/icons-material";
import { Button } from "@mui/material";
import CustomAddStudentDialogBox from "../Components/CustomAddStudentDialogBox/CustomAddStudentDialogBox";
import AddStudentDrawer from "../Components/AddStudentDrawer/AddStudentDrawer";
import Schools from "../Pages/School/School";
import { useEffect } from "react";
import { useState } from "react";
import Teachers from "../Pages/Teachers/Teachers";
import ContentManagement from "../Pages/ContentManagement/ContentManagement";
import ContentSubjectSelection from "../Pages/ContentSubjectSelection/ContentSubjectSelection";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import PPT from '../Assets/PPT.png';
import videos from "../Assets/videos.png";
import Ebooks from "../Assets/Ebooks.png";
import Tests from "../Assets/Tests.png";
import { checkEmptyObject } from "../Utils/Utils";
import MindgrapherReport from "../Components/MindgrapherReport/MindgrapherReport";
import ProductPurchase from "../Pages/ProductPurchase/ProductPurchase";
import StudentProductHistory from "../Pages/StudentProductHistory/StudentProductHistory";

const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Dashboard() {
  const location = useLocation();

  console.log("lOcations", location.pathname);
  const { logout,content_selection,setUrl,userState } = React.useContext(UserCredsContext);
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [dropdownOpne, setDropdownOpne] = useState(false) 
  const navigate = useNavigate();
  const [pathState, setPathState] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleRoute = (url) => {
    if (url === "logout") {
      logout();
      return;
    }
    console.log(url, "needtoknow");
    if(url === undefined){

    }else{
      if (url !== "") {
        navigate(`/dashboard/${url}`);
        setUrl(`/dashboard/${url}`)
        setOpen(true)
      } else {
        navigate(`/dashboard/content/select`)
        setOpen(true)
      }
    }
    
    
  };
  useEffect(() =>{
    handleRoute()
  },[])

  useEffect(() => {
    // console.log("Logogogohere", location.pathname);
    if (location.pathname === "/dashboard/students") {
      setPathState(true);
    } else {
      setPathState(false);
    }
  }, [location.pathname]);

  const handelDropdown = (text) =>{
    const newUrl = text?.list?.url
    // console.log(text?.list, "handelDrpown");
    // setDropdownOpne(!dropdownOpne)
    
    if(text?.list?.length > 0){
      setDropdownOpne(true)
      // navigate(`dashboard/${}`)
    } else{
      setDropdownOpne(false )
    }
  }
  const handleListItemClick = (index) => {
    // setSelectedIndex(index);
    console.log(index,"handleListItemClick");
  };

  const handleRoutefordropdown = (title,url) => {
    // console.log(url ,title ,"handleRoutefordropdown");
    // navigate(`${url}`)
    // // let val = url.includes('content');
    // let val = url.includes("content")
    // if (val) {
    //   const data = checkEmptyObject(content_selection)
    //   if (data){
    //     navigate(`/dashboard/${url}`);
    //   }else{
    //     navigate(`/dashboard/content/select`)
    //     setUrl(`/dashboard/${url}`)
    //   }
      
    // }
    if (url !== '') {
      let value = url.includes('content');
      if (value) {
        const data = checkEmptyObject(content_selection);
        console.log(data, "nrjuwygfsdbwgfjhlehdjhdksd");
        // debugger
        if (data) {
          navigate(`/dashboard/${url}`)
        } else {
          navigate(`/dashboard/content/select`)
          setUrl(`/dashboard/${url}`)
        }
      } else {
        navigate(`/dashboard/${url}`)
      }
    }
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div>
              {/* <Typography variant="h6" noWrap component="div">
                Dashboard
              </Typography> */}
            </div>

            {pathState && (
              <div style={{ display: "flex", gap: "15px" }}>
                <AddStudentDrawer />

                <CustomAddStudentDialogBox bulk={true} />
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img src={brainhapLogo} width={170} />

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {drawerOptionsNested.map((text, index) => (
            <>
            <ListItem
              key={text}
              disablePadding
              sx={{ display: "block" }}
              onClick={() => {handleRoute(text.url); handelDropdown(text) }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src={text.icon} style={{ width: "20px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={text.title}
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {
                  text?.list?.length > 0 ? <> {dropdownOpne ? <ExpandLess /> : <ExpandMore />} </> : ""
                }
                 
                {/* {open ? <ExpandLess /> : <ExpandMore />} */}
              </ListItemButton>
              
            </ListItem>
            <Collapse in={dropdownOpne} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {text?.list?.map((item, idx) => {
                    return (
                        <ListItemButton sx={{ pl: 6 }} 
                        onClick={() => {handleRoutefordropdown(item?.title, item?.url); handleListItemClick(idx)}} 
                        // style={(dataTitle == title && item.title == titles) ? {background: 'red'} : {}}
                        // selected={titles === title && selectedIndex === idx}
                        >
                            {/* <ListItemIcon>
                        <StarBorder />
                    </ListItemIcon> */}
                            <img src={item.icon} style={{ width: '25px', marginRight: "10px" }} />
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    )
                })}

            </List>
        </Collapse>
        </>
          ))}
          
        </List>
        <Divider />
        {/* <List>
          {["Option 5", "Option 6", "Option 7"].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          // backgroundColor: "#eaf6fa",
          height: "auto",
          width: "80%",
        }}
      >
        <DrawerHeader />
        <div style={{ paddingBottom: "0px" }}>
          {/* <CustomBreadCrumbs /> */}
        </div>

        <Routes>
          <Route exact strict path="students" element={<Students />} />
          <Route exact strict path="schools" element={<Schools />} />
          <Route exact strict path="teachers" element={<Teachers />} />
          <Route exact strict path="content/*" element={<ContentManagement/>} />
          <Route exact strict path="report/*" element={<MindgrapherReport/>} />
          <Route exact strict path="product" element={<ProductPurchase />} />
          <Route exact strict path="product/:id" element={<StudentProductHistory />} />
          {/* <Route exact strict path="content/select" element={<ContentSubjectSelection/>} /> */}
        </Routes>
      </Box>
    </Box>
  );
}

const drawerOptionsNested = [
  {
    type_id: 0,
    img: "",
    title: "Students",
    url: "students",
    icon: studentStroke,
    iconTwo: "",
  },
  {
    type_id: 2,
    img: "",
    title: "Schools",
    url: "schools",
    icon: schoolStroke,
    iconTwo: "",
  },
  {
    type_id: 2,
    img: "",
    title: "Teachers",
    url: "teachers",
    icon: schoolStroke,
    iconTwo: "",
  },
  {
    type_id: 3,
    img: "",
    title: "Content",
    // url: "",
    icon: schoolStroke,
    iconTwo: "",
    list: [
      // {
      //   title: "Select",
      //   id: 20,
      //   url: "content/select",
      //   icon: PPT
      // },
      {
        title: "Ebooks",
        id: 20,
        url: "content/ebooks",
        icon: Ebooks
      },
      {
        title: "PPT",
        id: 21,
        // url: "content/ppt",
        url: "content/ppt/view",
        icon: PPT
      },
      {
        title: "Lesson Plan",
        id: 22,
        url: "content/lesson-plan",
        icon: Ebooks
      },
      // {
      //   title: "Mindmaps",
      //   id: 23,
        // url: "content/mindmap",
      //   icon: Mindmap
      // }
      , {
        title: "MCQ",
        id: 24,
        url: "content/test/testList",
        icon: Tests,
      },
      {
        title: "Worksheets",
        id: 25,
        url: "content/qa/qalist",
        icon: Tests,
      },
      // {
      //   title: "Q&A",
      //   id: 25,
      //   url: "content/qa/qalist",
      //   icon: Tests,
      // },
      // {
      //   title: "Assignments",
      //   id: 26,
      //   url: "content/assignment/assignList",
      //   icon: Assignments
      // },
      // {
      //   title: "Activity Videos",
      //   id: 27,
      //   url: "content/videos/videoslist",
      //   icon: videos
      // },
    ]
  },

  {
    type_id: 2,
    img: "",
    title: "Product Purchase",
    url: "product",
    icon: schoolStroke,
    iconTwo: "",
  },

  {
    img: "",
    title: "Mindgrapher Report",
    url: "report",
    icon: studentStroke,
    iconTwo: "",

  },
  {
    type_id: 2,
    img: "",
    title: "Logout",
    url: "logout",
    icon: logout,
    iconTwo: "",
  },
];

const dashBack = ["/dashboard/students", "/dashboard/schools"];
