import React, { useContext, useEffect, useState } from "react";
import { Autocomplete, Button, Drawer, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import CustomSnackBar from "../CustomSnackBar/CustomSnackBar";
import SearchAndDropdown from "../SearchAndDropdown/SearchAndDropdown";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./index.module.css";
import dragIcon from "../../Assets/filedrag.png";
import { TextFields } from "@mui/icons-material";
import axios from "axios";
import { base_url } from "../../Utils/Network";

function AddCourseModel({
  addCourseOpen,
  setAddCourseOpen,
  SendToVideo = () => {},
  editCourseData = "",
}) {
  // const [open, setOpen] = useState(false);
  const fileInput = React.useRef(null);
  const interestInput = React.useRef(null);
  const [fileName, setFileName] = React.useState("");
  const [imageFile, setImageFile] = useState("");
  const [file, setFile] = React.useState(null);
  const [fileName2, setFileName2] = useState(null);
  const [iconFile, setIconFile] = useState("");
  const [file2, setFile2] = useState(null);
  const { content_selection, token_data } = useContext(UserCredsContext);
  const [courseData, setCourseData] = useState({
    title: "",
    primary_color: "#000000",
    secondary_color: "#ffffff",
  });
  const [snackbarData, setSnackbarData] = React.useState({
    message: "",
    open: false,
    responseType: "",
  });
  const [fileUplodeResponce, setFileUploadeResponce] = useState({});
  const [backDropOpen, setBackDropOpen] = useState(false);
  console.log(content_selection?.grade, editCourseData, "AddCourseModel");

  useEffect(() => {
    setCourseData({
      title: editCourseData?.title,
      primary_color: editCourseData?.primary_color,
      secondary_color: editCourseData?.secondary_color,
    });
    setIconFile(editCourseData?.icon);
    setImageFile(editCourseData?.image);
  }, [editCourseData]);

  const handleClickOpen = () => {
    setAddCourseOpen(true);
  };

  const handleClose = () => {
    setAddCourseOpen(false);
    setCourseData({
      title: "",
      primary_color: "#000000",
      secondary_color: "#ffffff",
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleClick = () => {
    fileInput.current.click();
    console.log(fileInput, "jhdfjhjgf");
  };

  const handleClick2 = () => {
    interestInput.current.click();
  };

  const handleDrop = (e, fileType) => {
    e.preventDefault();

    let selectedFile = null;
    let files = Array.from(e.target.files);
    // debugger;
    if (e?.target?.files) {
      selectedFile = e.target.files[0];
      files = Array.from(e.target.files);
    } else if (e?.dataTransfer?.files) {
      selectedFile = e.dataTransfer.files[0];
      files = Array.from(e.target.files);
    }

    if (selectedFile) {
      if (fileType === "image") {
        setFileName(selectedFile);
        const fileUrls = files.map((file) => URL.createObjectURL(file));
        setImageFile(fileUrls);
        // setFile(selectedFile); // Assuming setFile is used for the first file
      } else if (fileType === "icon") {
        setFileName2(selectedFile);
        const fileUrls = files.map((file) => URL.createObjectURL(file));
        setIconFile(fileUrls);
        // setFile2(selectedFile); // Save the file to file2 state
      }

      const reader = new FileReader();
      reader.onload = () => {
        if (fileType === "image") {
          setFile(reader.result);
        } else if (fileType === "icon") {
          setFile2(reader.result); // Read and save file2's data
        }
      };
      //   reader.readAsText(selectedFile); // Reads the file content
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    console.log(value, name, "handleChange");
    setCourseData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const CreateCourse = () => {
    // debugger
    if (editCourseData.id) {
      var formData = new FormData();
      if (content_selection?.grade) {
        formData.append("grade", content_selection?.grade);
      }

      if (courseData?.title) {
        formData.append("title", courseData?.title);
      }
      if (courseData?.primary_color) {
        formData.append("primary_color", courseData?.primary_color);
      }
      if (courseData?.secondary_color) {
        formData.append("secondary_color", courseData?.secondary_color);
      }

      if (fileName) {
        formData.append("image", fileName);
      }
      if (fileName2) {
        formData.append("icon", fileName2);
      }

      axios({
        method: "patch",

        url: `${base_url}content/add-course/${editCourseData?.id}/`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token_data.access}`,
        },
      })
        .then((response) => {
          console.log("MIndResponse", response);
          if (
            response?.status === 200 ||
            response?.status === 201 ||
            response?.status === 204
          ) {
            setFileUploadeResponce(response?.data?.data);
            setCourseData({
              title: "",
              primary_color: "#000000",
              secondary_color: "#ffffff",
            });
            setFileName("");
            setFile(null);
            setFileName2(null);
            setFile2(null);
            handleClose();
          }
          handleClose();
          setBackDropOpen(false);
          SendToVideo(content_selection?.grade);
        })

        .catch((err) => {
          setSnackbarData({
            message: "Error while updating mindgraph details",
            open: true,
            responseType: "error",
          });
          setBackDropOpen(false);
        });
    } else {
      var formData = new FormData();
      if (content_selection?.grade) {
        formData.append("grade", content_selection?.grade);
      }

      if (courseData?.title) {
        formData.append("title", courseData?.title);
      }
      if (courseData?.primary_color) {
        formData.append("primary_color", courseData?.primary_color);
      }
      if (courseData?.secondary_color) {
        formData.append("secondary_color", courseData?.secondary_color);
      }

      if (fileName) {
        formData.append("image", fileName);
      }
      if (fileName2) {
        formData.append("icon", fileName2);
      }

      axios({
        method: "post",

        url: `${base_url}content/add-course/`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token_data.access}`,
        },
      })
        .then((response) => {
          console.log("MIndResponse", response);
          if (
            response?.status === 200 ||
            response?.status === 201 ||
            response?.status === 204
          ) {
            setFileUploadeResponce(response?.data?.data);
            setCourseData({
              title: "",
              primary_color: "#000000",
              secondary_color: "#ffffff",
            });
            setFileName("");
            setFile(null);
            setFileName2(null);
            setFile2(null);
            handleClose();
          }
          handleClose();
          setBackDropOpen(false);
          SendToVideo(content_selection?.grade);
        })

        .catch((err) => {
          setSnackbarData({
            message: "Error while updating mindgraph details",
            open: true,
            responseType: "error",
          });
          setBackDropOpen(false);
        });
    }
  };

  console.log(iconFile, fileName2, "wlefijjjsakjsnn");

  return (
    <>
      <div>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
            <Drawer anchor={anchor} open={addCourseOpen} onClose={handleClose}>
              <Typography sx={{ padding: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                    marginTop: "50px",
                  }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </div>
                <Box sx={{ width: "100%" }}>
                  <div className={styles.con}>
                    <TextField
                      fullWidth
                      onChange={(e) => handleChange(e)}
                      // onClick={() => { setActive(items.id) }}
                      name="title"
                      value={courseData?.title}
                      label="Course"
                      size="small"
                      required
                    />
                    <TextField
                      fullWidth
                      type="color"
                      onChange={(e) => handleChange(e)}
                      // onClick={() => { setActive(items.id) }}
                      name="primary_color"
                      value={courseData?.primary_color || "#000000"}
                      label="Course Text Colour on website"
                      size="small"
                      placeholder="eg. #ffffff"
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      fullWidth
                      type="color"
                      onChange={(e) => handleChange(e)}
                      // onClick={() => { setActive(items.id) }}
                      name="secondary_color"
                      value={courseData?.secondary_color || "#ffffff"}
                      label="Course Background Colour on Website"
                      size="small"
                      placeholder="eg. #ffffff"
                      required
                      InputLabelProps={{ shrink: true }}
                    />

                    {/* {error && <p>{error}</p>} */}
                    <h4 style={{ marginBottom: "0px" }}>Upload Course Image</h4>
                    <div className={styles.top}>
                      {!imageFile ? (
                        <div
                          //   onDragOver={handleDragOver}
                          onDrop={(e) => handleDrop(e, "EdfFILE")}
                          className={styles.dropBoxStyles}
                        >
                          <div
                            className={styles.flexStyleDropBox}
                            onClick={handleClick}
                          >
                            <p
                              style={{
                                width: "100%",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                            >
                              <img src={dragIcon} alt="dradanddrop" />
                              <p>Drag & Drop the Files or click here</p>
                            </p>
                            <input
                              ref={fileInput}
                              type="file"
                              style={{ display: "none" }}
                              onChange={(e) => handleDrop(e, "image")}
                              accept=".png,.jpg,.jpeg,.svg"
                            ></input>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <img
                            src={imageFile}
                            style={{ width: "100px", height: "100px" }}
                          />
                          <CloseIcon
                            onClick={() => setImageFile("")}
                            style={{
                              position: "absolute",
                              top: "-10px",
                              right: "-10px",
                              backgroundColor: "white",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <h4 style={{ marginBottom: "0px" }}>Upload Course Icon</h4>
                    <div className={styles.top}>
                      {!iconFile ? (
                        <div
                          onDragOver={handleDragOver}
                          onDrop={(e) => handleDrop(e, "CsvFILE")}
                          className={styles.dropBoxStyles}
                        >
                          <div
                            className={styles.flexStyleDropBox}
                            onClick={handleClick2}
                          >
                            <p
                              style={{
                                width: "100%",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                            >
                              <img src={dragIcon} alt="dradanddrop" />
                              <p>Drag & Drop the Files or click here</p>
                            </p>
                            <input
                              ref={interestInput}
                              type="file"
                              style={{ display: "none" }}
                              onChange={(e) => handleDrop(e, "icon")}
                              accept=".png,.jpg,.jpeg,.svg"
                            ></input>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <img
                            src={iconFile}
                            style={{ width: "100px", height: "100px" }}
                          />
                          <CloseIcon
                            onClick={() => setIconFile("")}
                            style={{
                              position: "absolute",
                              top: "-10px",
                              right: "-10px",
                              backgroundColor: "white",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <Button
                      style={{
                        backgroundColor: "#032C6B",
                        width: "100%",
                      }}
                      type="submit"
                      // size="small"
                      variant="contained"
                      onClick={() => {
                        CreateCourse();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </Box>
              </Typography>
            </Drawer>
          </React.Fragment>
        ))}
      </div>

      <Backdrop
        sx={{ color: "#ffffff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        // open={backDropOpen}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <CustomSnackBar
        message={snackbarData.message}
        openSnack={snackbarData.open}
        severity={snackbarData.responseType}
      />
    </>
  );
}

export default AddCourseModel;
